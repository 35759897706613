import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { format } from "date-fns";
import * as api from "./api/analytics";

function App() {
    const [isLoadingStationData, setIsLoadingStationData] = useState(true);
    const [isLoadingShowData, setIsLoadingShowData] = useState(true);
    const [stationLoadedData, setStationLoadedData] = useState([]);
    const [showLoadedData, setShowLoadedData] = useState([]);
    const [stationGoldenMinutes, setStationGoldenMinutes] = useState([]);
    const [showGoldenMinutes, setShowGoldenMinutes] = useState([]);
    const [latestUpdateDate, setLatestUpdateDate] = useState(null);
    const reloadTimeout = 30000;

    const getDayOfTheWeek = (day) => {
        let days = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

        return days[day] || null;
    };

    const getTodaysDate = () => {
        const today = new Date();

        return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    };

    const loadStationData = async () => {
        // if (!stationLoadedData.length) {
        //   setIsLoadingStationData(true);
        // }
        const result = await api.getData({
            chart_id: "listeners",
            filters: {
                date_order_date_range: `daterange::from:[${getTodaysDate()}]to:[${getTodaysDate()}]`,
            },
        });
        if (result && result.ok && result.data && result.data.data && !result.data.error) {
            let newGoldenMinutes = [];
            for (let col in result.data.data[0]) {
                if (col == 0) {
                    continue;
                }
                newGoldenMinutes[col] = {
                    label: result.data.data[0][col],
                    best: 0,
                    hour: "00:00",
                };
            }

            for (let row in result.data.data) {
                if (row == 0) {
                    continue;
                }
                for (let col in result.data.data[row]) {
                    if (col == 0) {
                        continue;
                    }
                    if (parseInt(result.data.data[row][col]) > parseInt(newGoldenMinutes[col].best)) {
                        newGoldenMinutes[col].best = parseInt(result.data.data[row][col]);
                        newGoldenMinutes[col].hour = result.data.data[row][0];
                    }
                }
            }
            newGoldenMinutes.sort((a, b) => (a.best > b.best ? -1 : 1));
            setStationGoldenMinutes(newGoldenMinutes);
            setStationLoadedData(result.data.data);
            setLatestUpdateDate(new Date());
        } else {
            alert("No good data??");
        }
        setIsLoadingStationData(false);
        setTimeout(loadStationData, reloadTimeout);
    };

    const loadShowData = async () => {
        // if (!stationLoadedData.length) {
        //   setIsLoadingShowData(true);
        // }
        const result = await api.getData({
            chart_id: "show_listeners",
            filters: {
                date_show_listeners_date_range: `daterange::from:[${getTodaysDate()}]to:[${getTodaysDate()}]`,
            },
        });
        if (result && result.ok && result.data && result.data.data && !result.data.error) {
            let outputData = [...result.data.data];
            const colsToRemove = [];
            for (let col in outputData[0]) {
                let remove = true;
                if (col == 0) {
                    continue;
                }
                for (let row in outputData) {
                    if (row == 0) {
                        continue;
                    }
                    if (0 != outputData[row][col]) {
                        remove = false;
                        break;
                    }
                }
                if (remove) {
                    colsToRemove.push(col);
                }
            }
            console.log("Columns to remove: ", colsToRemove);
            let finalOutputData = [];
            for (let row in outputData) {
                let newRow = [];
                for (let col in outputData[row]) {
                    if (!colsToRemove.find((c) => c == col)) {
                        newRow.push(outputData[row][col]);
                    }
                }
                finalOutputData.push(newRow);
            }

            let newGoldenMinutes = [];
            for (let col in finalOutputData[0]) {
                if (col == 0) {
                    continue;
                }
                newGoldenMinutes[col] = {
                    label: finalOutputData[0][col],
                    best: 0,
                    hour: "00:00",
                };
            }

            for (let row in finalOutputData) {
                if (row == 0) {
                    continue;
                }
                for (let col in finalOutputData[row]) {
                    if (col == 0) {
                        continue;
                    }
                    if (parseInt(finalOutputData[row][col]) > parseInt(newGoldenMinutes[col].best)) {
                        newGoldenMinutes[col].best = parseInt(finalOutputData[row][col]);
                        newGoldenMinutes[col].hour = finalOutputData[row][0];
                    }
                }
            }
            newGoldenMinutes.sort((a, b) => (a.best > b.best ? -1 : 1));
            setShowGoldenMinutes(newGoldenMinutes);
            setShowLoadedData(finalOutputData);
            setLatestUpdateDate(new Date());
        } else {
            alert("No good data??");
        }
        setIsLoadingShowData(false);
        setTimeout(loadShowData, reloadTimeout);
    };

    useEffect(() => {
        console.log("Mounted!");
        loadStationData();
        loadShowData();
    }, []);

    return (
        ((isLoadingStationData || isLoadingShowData) && (
            <div>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </div>
        )) || (
            <div className="flex flex-col items-center p-4 ">
                <div className="w-full">
                    <div className="flex items-center justify-center border-slate-200 mb-4">
                        <img
                            src="https://assets.ondavasca.com/front/ondavasca/dist/img/logo-onda-vasca.svg"
                            alt="Logotipo de Onda Vasca"
                            width="154"
                            height="64"
                            style={{ maxWidth: "7rem" }}
                        />
                        <div className="ml-4 flex items-center justify-center">
                            <h1 className="text-left text-lg text-slate-600">
                                Estadísticas{" "}
                                <span className="font-bold">
                                    {getDayOfTheWeek(new Date().getDay())}, {format(new Date(), "dd/MM/yyyy")}
                                </span>
                            </h1>
                            {(latestUpdateDate && (
                                <h2 className="text-left text-md text-slate-700 ml-4 bg-slate-100 p-2">
                                    Última Actualización <span className="font-bold">{format(latestUpdateDate, "HH:mm")}</span>
                                </h2>
                            )) ||
                                ""}
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-wrap">
                    <div className="w-full lg:w-2/3 lg:order-last flex flex-col items-center flex-grow flex-shrink-0 relative">
                        <div className="bg-slate-100 rounded-md p-4 w-full mb-2 flex-grow">
                            <Chart
                                chartType="LineChart"
                                width="100%"
                                height="100%"
                                data={stationLoadedData}
                                options={{
                                    title: "Oyentes por Emisora",
                                    legend: { position: "bottom", textStyle: { fontSize: "1rem" } },
                                }}
                            />
                        </div>
                        <div className="bg-slate-100 rounded-md p-4 w-full mt-2 mb-4 flex-grow">
                            <Chart
                                chartType="LineChart"
                                width="100%"
                                height="100%"
                                data={showLoadedData}
                                options={{
                                    title: "Oyentes por Programa",
                                    legend: { position: "bottom", textStyle: { fontSize: "0.8rem" } },
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 lg:pr-4">
                        {(stationGoldenMinutes.length && (
                            <div className="bg-slate-100 rounded-md p-4">
                                <h2 className="text-left text-lg mb-4">Minutos de Oro por Emisora</h2>
                                <table className="border-collapse table-fixed w-full text-sm">
                                    <thead>
                                        <tr>
                                            <th
                                                style={{ width: "50%" }}
                                                className="border-b dark:border-slate-600 font-medium p-2 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left"
                                            ></th>
                                            <th className="border-b dark:border-slate-600 font-medium p-2 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                Oyentes
                                            </th>
                                            <th className="border-b dark:border-slate-600 font-medium p-2 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                Hora
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white dark:bg-slate-800">
                                        {stationGoldenMinutes.map((gm, gmIndex) => (
                                            <tr key={gmIndex}>
                                                <td className="border-b border-slate-100 dark:border-slate-700 p-2 pl-8 text-slate-500 dark:text-slate-400 font-bold">
                                                    {gm.label.charAt(0).toUpperCase() + gm.label.slice(1)}
                                                </td>
                                                <td className="border-b border-slate-100 dark:border-slate-700 p-2 text-slate-500 dark:text-slate-400">
                                                    {gm.best}
                                                </td>
                                                <td className="border-b border-slate-100 dark:border-slate-700 p-2 text-slate-500 dark:text-slate-400">
                                                    {gm.hour}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )) ||
                            ""}

                        {(showGoldenMinutes.length && (
                            <div className="bg-slate-100 rounded-md p-4 mt-4">
                                <h2 className="text-left text-lg mb-4">Minutos de Oro por Programa</h2>
                                <table className="border-collapse table-fixed w-full text-sm">
                                    <thead>
                                        <tr>
                                            <th
                                                style={{ width: "50%" }}
                                                className="border-b dark:border-slate-600 font-medium p-2 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left"
                                            ></th>
                                            <th className="border-b dark:border-slate-600 font-medium p-2 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                Oyentes
                                            </th>
                                            <th className="border-b dark:border-slate-600 font-medium p-2 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                                Hora
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white dark:bg-slate-800">
                                        {showGoldenMinutes.map((gm, gmIndex) => (
                                            <tr key={gmIndex}>
                                                <td className="border-b border-slate-100 dark:border-slate-700 p-2 pl-8 text-slate-500 dark:text-slate-400 font-bold">
                                                    {gm.label}
                                                </td>
                                                <td className="border-b border-slate-100 dark:border-slate-700 p-2 text-slate-500 dark:text-slate-400">
                                                    {gm.best}
                                                </td>
                                                <td className="border-b border-slate-100 dark:border-slate-700 p-2 text-slate-500 dark:text-slate-400">
                                                    {gm.hour}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )) ||
                            ""}
                    </div>
                </div>
            </div>
        )
    );
}

export default App;
